import './SlidingGallery.css';
import React, { Component } from 'react';

import { default as ImageComing } from './ImageComing/ImageComing';
import { default as GalleryMenu } from './GalleryMenu/GalleryMenu'

import image1 from './resources/1.jpg';
import image2 from './resources/2.jpg';
import image3 from './resources/3.jpg';
import image4 from './resources/4.jpg';


export default class SlidingGallery extends Component {
  constructor(props) {
    super(props);
    this.state = ({images: [image1, image2, image3, image4], going: image1, coming: image2, iterator: 0, iteratorNext: 1, interval1: 0});
    this.changeSelected = this.changeSelected.bind(this);
    this.automaticallySwitchImages = this.automaticallySwitchImages.bind(this);
  }
  componentDidMount() {
    this.automaticallySwitchImages();
  }
  automaticallySwitchImages() {
    const interval1 = window.setInterval(() => {
      this.setState({iterator: this.state.iterator + 1, iteratorNext: this.state.iteratorNext + 1});
      if(this.state.iterator > this.state.images.length - 1) {
        this.setState({iterator: 0});
      }
      if(this.state.iteratorNext > this.state.images.length - 1) {
        this.setState({iteratorNext: 0});
      }
      this.setState({going: this.state.images[this.state.iterator], coming: this.state.images[this.state.iteratorNext]});
    }, 3000);
    this.setState({interval1: interval1});
  }
  changeSelected(event) {
    window.clearInterval(this.state.interval1);
    const id = event.target.id;
    const numberSelected = parseInt(id.slice(13,16) - 2);
    this.setState({iterator: numberSelected, iteratorNext: numberSelected + 1});
    if(numberSelected > this.state.images.length - 1) {
      this.setState({iterator: 0});
    }
    if(numberSelected < 0) {
      this.setState({iterator: this.state.images.length - 1});
    }
    if(numberSelected + 1 > this.state.images.length - 1) {
      this.setState({iteratorNext: 0});
    }
    if(numberSelected + 1 < 0) {
      this.setState({iteratorNext: this.state.images.length});
    }
    window.setTimeout(() => {
      this.setState({going: this.state.images[this.state.iterator], coming: this.state.images[this.state.iteratorNext]});
    }, 0);
      this.automaticallySwitchImages();
    
  }
  componentWillUnmount() {
    clearTimeout(this.state.interval1);
  }
  render() {
    return (
      <div className="SlidingGallery">
          <div className="galleryImages">
            <ImageComing src={this.state.coming} width="400px" height="250px" id="ComingImage"/>
            <ImageComing src={this.state.going} width="400px" height="250px" id="GoingImage"/>
            <GalleryMenu items={this.state.images} selected={this.state.coming} iterator={this.state.iterator - 1} onClick={this.changeSelected}/>
          </div>
          
      </div>
    )
  }
}
