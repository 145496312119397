import React, { Component } from 'react';
import './Banner.css';

import { default as ExpandButton } from './ExpandButton/ExpandButton';
import logo from '../navBar/NavBarLogo/resoures/whiteLogo.svg';


export default class Banner extends Component {
    constructor(props) {
        super(props);
        this.handleMouseOver = this.handleMouseOver.bind(this);
    }

    handleMouseOver() {

    }

  render() {
    return (
        <div id="Banner">
            <div id="BannerTexts">
                <img src={logo} alt="Logo de accesorios"></img>
                <h2>LA MEJOR OPCI&Oacute;N PARA LA PERSONALIZACI&Oacute;N DE TUS EQUIPOS M&Oacute;VILES.</h2>
            </div>
            <ExpandButton />
        </div>
    )
  }
}
