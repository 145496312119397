import React, { Component } from 'react';
import './MobileSelector.css';
import { default as StateButton } from './StateButton/StateButton';
import ExpandIcon from './resources/expand.svg';
export default class MobileSelector extends Component {
    constructor(props) {
        super(props);
        this.state = ({menuOpened: false});
        this.switchMenu = this.switchMenu.bind(this);
    }
    switchMenu() {
        if(this.state.menuOpened === false) {
            this.setState({menuOpened: true});
            document.getElementById('expandIcon').style = 'transform: rotate(-180deg);';
        } else {
            this.setState({menuOpened: false});
            document.getElementById('expandIcon').style = 'transform: rotate(0deg);';
        }
    }
  render() {
    return (
      <div id="MobileSelector">
            <button onClick={this.switchMenu} id="openMenu"><img src={ExpandIcon} id="expandIcon" alt="Icono para expandir"/>UBICACIONES</button>
          {this.state.menuOpened ? <div id="StateButtons">
            <StateButton name="QUERÉTARO" id="QUERETARO" selectedOption={this.props.selected} onClick={this.props.changeSelected} locations={[`ANTEA (Food Court)`, `ANTEA (Palacio de Hierro)`, 'HEB (El Refugio)', 'GALERÍAS (Próximamente)', 'Premium Outlets (Próximamente)']} changeSelectedLocation={this.props.changeSelectedLocation} selectedLocation={this.props.selectedLocation} switchMenu={this.switchMenu}/>
            <StateButton name="SAN LUIS POTOSI" id="SAN LUIS POTOSI" selectedOption={this.props.selected} onClick={this.props.changeSelected} locations={['CENTRO COMERCIAL EL DORADO', 'PLAZA SENDERO']} changeSelectedLocation={this.props.changeSelectedLocation} selectedLocation={this.props.selectedLocation} switchMenu={this.switchMenu}/>
            <StateButton name="IRAPUATO" id="IRAPUATO" selectedOption={this.props.selected} onClick={this.props.changeSelected} locations={['CENTRO COMERCIAL CIBELES']} changeSelectedLocation={this.props.changeSelectedLocation} selectedLocation={this.props.selectedLocation} switchMenu={this.switchMenu}/>
            <StateButton name="PUEBLA" id="PUEBLA" selectedOption={this.props.selected} onClick={this.props.changeSelected} locations={['CENTRO COMERCIAL ANGELÓPOLIS (Próximamente)']} changeSelectedLocation={this.props.changeSelectedLocation} selectedLocation={this.props.selectedLocation} switchMenu={this.switchMenu}/>
          </div> : null}
      </div>
    )
  }
}
