import React, { Component } from 'react';
import './MenuButtons.css';

export default class MenuButtons extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    componentDidMount() {
        document.getElementById('MenuButtons').style.transform = 'translate(0,-100vw)';
        window.setTimeout(() => {
            document.getElementById('MenuButtons').style.transform = 'translate(0,0vw)';
        }, 0);
        
    }
    componentDidUpdate() {
        if(this.props.willUnmount) {
            document.getElementById('MenuButtons').style.transform = 'translate(0,-100vw)';
        }
    }
    handleClick(event) {
        this.props.switchMenu();
        const id = event.target.value;
        window.scrollTo(0,document.getElementById(id).offsetTop - (getComputedStyle(document.documentElement).fontSize.slice(0, Number.parseInt(getComputedStyle(document.documentElement).fontSize.length, 10) - 2) * 5));
    }
  render() {
    return (
      <div id="MenuButtons">
          <button onClick={this.handleClick} value='ProductGallery'>PRODUCTOS</button>
          <button onClick={this.handleClick} value='Locations'>UBICACIONES</button>
          <button onClick={this.handleClick} value='Nosotros'>NOSOTROS</button>
          <button onClick={this.handleClick} value='Footer'>CONTACTO</button>
      </div>
    )
  }
}
