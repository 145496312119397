import React, { Component } from 'react';
import './ImageComing.css'; 
export default class ImageComing extends Component {
  componentDidUpdate() {
    const comingImage = document.getElementById('ComingImage');
    comingImage.classList = 'justMounted';
    window.setTimeout(() => {
      comingImage.classList = 'moveToThePosition';
    },0);
    const goingImage = document.getElementById('GoingImage');
    goingImage.classList = 'moveToTheCenter';
    window.setTimeout(() => {
      goingImage.classList = 'moveOutside';
    },0);
  }
  render() {
    return (
      <div className="ImageComing">
        <img src={this.props.src} id={this.props.id} alt="Gallery"/>
      </div>
    )
  }
}
