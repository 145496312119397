import React, { Component } from 'react';

import './ExpandButton.css';

import expand from '../resources/expand.svg';
import expand2 from '../resources/expand2.svg';

export default class ExpandButton extends Component {
    constructor(props) {
        super(props);
        this.state = ({ image: expand });
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleMouseEnter() {
        this.setState({ image: expand2 });
    }

    handleMouseLeave() {
        this.setState({ image: expand });
    }

    handleClick() {
        window.scrollTo(0,window.innerHeight - (getComputedStyle(document.documentElement).fontSize.slice(0, Number.parseInt(getComputedStyle(document.documentElement).fontSize.length, 10) - 2) * 5));
    }

    render() {
        return (
            <div id="ExpandButton">
                <button onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} onClick={this.handleClick}>
                    <img alt="Expandir contenido" src={this.state.image}></img>
                </button>
            </div>
        
        )
    }
}
