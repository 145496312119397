import React, { Component } from 'react';
import './StateMenu.css';
import { default as LocationButton } from './LocationButton/LocationButton';
export default class StateMenu extends Component {
  constructor(props) {
    super(props);
    this.state = ({locations: this.props.locations.map(x => {return x})});
  }

  render() {
    return (
      <div className="StateMenu">
        {this.state.locations.map((x) => {
          return (<LocationButton name={x} id={this.props.id} key={x} changeSelectedLocation={this.props.changeSelectedLocation} selectedLocation={this.props.selectedLocation}/>)
        })}
      </div>
    )
  }
}
