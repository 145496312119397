import React, { Component } from 'react';
import './StateButton.css';
import ExpandIcon from './resources/expand.svg';
import { default as StateMenu } from './StateMenu/StateMenu';
export default class StateButton extends Component {
    constructor(props) {
        super(props);
        this.state = ({ name: this.props.name, hover: false, menuOpened: false});
        this.selectedBackgroundColor = this.selectedBackgroundColor.bind(this);
        this.handleOnMouseEnter = this.handleOnMouseEnter.bind(this);
        this.handleOnMouseLeave = this.handleOnMouseLeave.bind(this);
        this.switchMenu = this.switchMenu.bind(this);
    }
    handleOnMouseEnter() {
      this.setState({hover: true});
      this.switchMenu();
    }
    handleOnMouseLeave() {
      this.setState({hover: false});
      this.switchMenu();
    }

    selectedBackgroundColor() {
        if(this.props.name === this.props.selectedOption) {
            return {backgroundColor: 'rgb(7, 63, 0)'};
        }
    }
    switchMenu() {
      if(this.state.menuOpened === false) {
          this.setState({menuOpened: true});
          document.getElementById(`${this.props.id}+deskExpandIcon`).style = 'transform: rotate(-180deg);';
      } else {
          this.setState({menuOpened: false});
          document.getElementById(`${this.props.id}+deskExpandIcon`).style = 'transform: rotate(0deg);';
      }
  }
  render() {
    
    return (
      <div class="StateButton" style={this.selectedBackgroundColor()} onClick={this.props.onClick} id={this.props.id} onMouseEnter={this.handleOnMouseEnter} onMouseLeave={this.handleOnMouseLeave}>
          <p onClick={this.props.onClick} id={this.props.id} className='disable-select'>{this.state.name}<img src={ExpandIcon} id={`${this.props.id}+deskExpandIcon`} alt="Icono para expandir" class="expandIconDesk"/></p>
          {this.state.hover ? <StateMenu locations={this.props.locations} id={this.props.id} changeSelectedLocation={this.props.changeSelectedLocation} selectedLocation={this.props.selectedLocation}/> : ''}
      </div>    
    )
  }
}
