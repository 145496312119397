import React, { Component } from 'react';
import './LocationButton.css';

export default class LocationButton extends Component {
    constructor(props) {
        super(props);
        this.logIt = this.logIt.bind(this);
        this.selectedBackgroundColor = this.selectedBackgroundColor.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
    }
    logIt() {
        console.log('test');
    }
    selectedBackgroundColor() {
        if(this.props.name === this.props.selectedLocation) {
            return {backgroundColor: 'rgb(7, 63, 0)'};
        }
    }
    handleOnClick(event) {
        this.props.changeSelectedLocation(event);
        this.props.switchMenu();
        
    }
  render() {
    return (
        <div className='LocationButtonMob'>
            <button onClick={this.handleOnClick} id={this.props.id} name={this.props.name} style={this.selectedBackgroundColor()}>{this.props.name}</button>
        </div>
    )
  }
}

