import React, { Component } from 'react';
import './Nosotros.css';

import SlidingGallery from './SlidingGallery/SlidingGallery';

export default class Nosotros extends Component {
  render() {
    return (
      <div id="Nosotros">
          <h2>&iquest;Qui&eacute;nes somos?</h2>
          <div id="Nosotros-Content">
            <SlidingGallery />
            <div id="Nosotros-text">
            <p>Es una empresa mexicana que naci&oacute; en 2007, debido a la creciente demanda existente en el mercado nacional al uso, operaci&oacute;n y equipamiento de Smartphones y Tablets personales.

Dicha necesidad se satisface por medio de la venta de accesorios, y asesor&iacute;a personalizada en los productos y novedades tecnol&oacute;gicas, con el apoyo de nuestro personal calificado. Tenemos presencia en los principales centros comerciales del pa&iacute;s.</p>
            </div>  
          </div>
      </div>
    )
  }
}
