import React from 'react';
import './App.css';

import { default as Banner } from '../Banner/Banner';
import { NavBar} from '../navBar/NavBar.js';
import { default as ProductGallery } from '../ProductGallery/ProductGallery';
import { default as Locations } from '../Locations/Locations.js';
import { default as Nosotros } from '../Nosotros/Nosotros';
import { default as Footer } from '../Footer/Footer';
import { default as WhatsappButton } from '../WhatsappButton/WhatsappButton';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
       width: window.innerWidth, 
       height: window.innerHeight,
       isMobile: false,
      };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateIsMobile = this.updateIsMobile.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    // mobile or not
    this.updateIsMobile();
    window.addEventListener('resize', this.updateIsMobile);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    this.updateWindowDimensions();
    // mobileUpdate
    window.removeEventListener('resize', this.updateIsMobile);
    this.updateIsMobile();
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  setIsMobile(newVal) {
    this.setState({ isMobile: newVal });
  }

  updateIsMobile() {
    if(this.state.width < 480) {
      this.setIsMobile('true');
    } else {
      this.setIsMobile('false');
    }
  }

  render() {
    return (
      <div id="App">
        <NavBar isMobile={this.state.isMobile} screenWidth={this.state.width}/>
        <Banner />
        {/* <p style={ {color: 'rgb(255,0,0)'} }>{this.state.width}{this.state.isMobile}</p> */}
        <ProductGallery isMobile={this.state.isMobile} />
        <Locations isMobile={this.state.isMobile}/>
        <Nosotros />
        <Footer />
        <WhatsappButton />
      </div>
    );
  }
}
