import React, { Component } from 'react';
import './MapSection.css';
export default class MapSection extends Component {
  constructor(props) {
    super(props);
    this.state = ({image: this.props.LocationImage});
  }
  componentDidMount() {
    this.setState({image: this.props.LocationImage});
  }

  static getDerivedStateFromProps(props, state) {
    if (props.LocationImage !== state.image) {
      return {
        image: props.LocationImage,
      };
    } else {
      return null;
    }
  }

  // UNSAFE_componentWillReceiveProps() {
  //   this.setState({image: this.props.LocationImage});
  // }

  componentDidUpdate() {
    
    if(this.state.image === this.props.LocationImage) {
      document.getElementById('galleryImage').style = 'width: 26rem; transition: none;';
    window.setTimeout(() => {
      document.getElementById('galleryImage').style = 'width: 30rem; transition: width 0.7s ease-in-out;';
    }, 0);
    }
  }
  componentWillUnmount() {
    this.setState({image: this.props.LocationImage});
  }

  render() {
    return (
      <div id="MapSection">
        <div id="LocationImage">
          <img src={this.props.LocationImage} alt="Imagen De La tienda" id="galleryImage" />
        </div>
        <div id="mapWrapper">
          <iframe title="map" src={this.props.LocationMap} allowFullScreen={true} loading="lazy"></iframe>
          <p className="writtenAddress">{this.props.LocationAddress}</p>
        </div>
      </div>
    )
  }
}
