import React, { Component } from 'react';

import './ProductImage.css';

export default class ProductImage extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            image: `url(${this.props.src})`,
            overlaytitle: '',
            overlayphrase: '',
        });
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    componentDidMount() {
      if(this.props.isMobile === 'true') {
        this.handleMouseEnter();
      }
    }
    componentDidUpdate() {
      if (this.props.isMobile === 'true' && this.props.currentId === this.props.id) {
        document.getElementById(this.props.id).style.display = 'flex';
      } else if (this.props.isMobile === 'true') {
        document.getElementById(this.props.id).style.display = 'none';
      }
    }

    handleMouseEnter() {
        this.setState({image: `linear-gradient(
            rgba(0, 0, 0, 0.45), 
            rgba(0, 0, 0, 0.45)
          ), url(${this.props.src})`,
            overlaytitle: this.props.overlaytitle,
            overlayphrase: this.props.overlayphrase
        });
    }

    handleMouseLeave() {
        this.setState({image: `url(${this.props.src})`,
        overlaytitle: '',
        overlayphrase: ''
    });
    }

  render() {
    return (
      <div className="ProductImage" style={
        this.props.isMobile === 'true' ? {backgroundImage: `linear-gradient(
        rgba(0, 0, 0, 0.45), 
        rgba(0, 0, 0, 0.45)
      ), url(${this.props.src})`} : {backgroundImage: this.state.image}
      } 
      onMouseEnter={this.handleMouseEnter} 
      onMouseLeave={this.handleMouseLeave} 
      id={this.props.id} >
          <h2>{this.props.isMobile === 'true' ? this.props.overlaytitle : this.state.overlaytitle}</h2>
          <h3>{this.state.overlayphrase}</h3>
      </div>
    )
  }
}
