import React, { Component } from 'react';
import './ProductGallery.css';

import image1 from './resources/phonecases2.jpg';
import image2 from './resources/popsocket.jpeg';
import image3 from './resources/temperedglass.jpg';
import image4 from './resources/lightring.jpg';
import image5 from './resources/cargadores2.jpg';

import { default as ProductImage } from './ProductImage/ProductImage';

export default class ProductGallery extends Component {
  constructor(props) {
    super(props);
    this.state = ({currentId: 'ProductsA'});
    this.cycleImagesOnMobile = this.cycleImagesOnMobile.bind(this);
  }
  cycleImagesOnMobile() {
    if(this.props.isMobile === 'true') {
      const interval1 = window.setInterval(() => {
        document.getElementById(this.state.currentId).style.zIndex = '0';
        switch(this.state.currentId) {
          case 'ProductsA':
            this.setState({currentId: 'ProductsB'});
          break;
          case 'ProductsB':
            this.setState({currentId: 'ProductsC'});
          break;
          case 'ProductsC':
            this.setState({currentId: 'ProductsD'});
          break;
          case 'ProductsD':
            this.setState({currentId: 'ProductsE'});
          break;
          default:
            this.setState({currentId: 'ProductsA'});
          break;
        }
      }, 4000);
      this.setState({interval1: interval1});
    }
  }
  componentDidMount() {
    this.cycleImagesOnMobile();
  }

  componentDidUpdate() {
    if(!this.state.interval1) {
      this.cycleImagesOnMobile();
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.interval1);
  }

  render() {
    return (
      <div id="ProductGallery">
          <ProductImage src={image1} overlaytitle='FUNDAS' id='ProductsA' isMobile={this.props.isMobile} currentId={this.state.currentId}/>
          <ProductImage src={image2} overlaytitle='POP SOCKETS' id='ProductsB' isMobile={this.props.isMobile} currentId={this.state.currentId}/>
          <ProductImage src={image3} overlaytitle='MICAS DE CRISTAL E HIDROGEL' id='ProductsC' isMobile={this.props.isMobile} currentId={this.state.currentId}/>
          <ProductImage src={image4} overlaytitle='AROS DE LUZ' id='ProductsD' isMobile={this.props.isMobile} currentId={this.state.currentId}/>
          <ProductImage src={image5} overlaytitle='CARGADORES Y CABLES' id='ProductsE' isMobile={this.props.isMobile} currentId={this.state.currentId}/>
      </div>
    )
  }
}
