import React, { Component } from 'react';
import './NavBarLogo.css';
import logo from './resoures/whiteLogo.svg';
export default class NavBarLogo extends Component {
  constructor(props) {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
  }
  handleOnClick() {
    window.scrollTo(0,0);
  }
  render() {
    return (
      <div id="NavBarLogo">
          <img src={logo} alt="Axesorios logotipo" onClick={this.handleOnClick}></img>
      </div>
    )
  }
}
