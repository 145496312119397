import React, { Component } from 'react';
import './NavBarMenu.css';
import { default as MobileMenu } from './MobileMenu/MobileMenu';
import { default as DesktopMenu } from './DesktopMenu/DesktopMenu';
export default class NavBarMenu extends Component {

    render() {
        return (
        <div id="NavBarMenu">
            {this.props.screenWidth < 900 ? <MobileMenu /> : <DesktopMenu />}
        </div>
        )
    }
}
