import React, { Component } from 'react';
import './StateButton.css';
import { default as StateMenu } from './StateMenu/StateMenu';
export default class StateButton extends Component {
    constructor(props) {
        super(props);
        this.state = ({ name: this.props.name, hover: false});
        this.selectedBackgroundColor = this.selectedBackgroundColor.bind(this);
        this.handleOnMouseEnter = this.handleOnMouseEnter.bind(this);
        this.handleOnMouseLeave = this.handleOnMouseLeave.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
    }
    handleOnMouseEnter() {
      this.setState({hover: true});
    }
    handleOnMouseLeave() {
      this.setState({hover: false});
    }
    handleOnClick(event) {
      this.props.onClick(event);
      if(this.state.hover === false) {
        this.setState({hover: true});
      } else {
        this.setState({hover: false});
      }
    }
    selectedBackgroundColor() {
        if(this.props.name === this.props.selectedOption) {
            return {backgroundColor: 'rgb(7, 63, 0)'};
        }
    }
  render() {
    
    return (
      <div class="StateButtonMob" style={this.selectedBackgroundColor()} onClick={this.handleOnClick} id={this.props.id}>
          <p onClick={this.handleOnClick} id={this.props.id} className='disable-select' >{this.state.name}</p>
          {this.state.hover ? <StateMenu locations={this.props.locations} id={this.props.id} changeSelectedLocation={this.props.changeSelectedLocation} selectedLocation={this.props.selectedLocation} switchMenu={this.props.switchMenu}/> : ''}
      </div>    
    )
  }
}
