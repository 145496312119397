import './MobileMenu.css';
import menuIcon from './resources/menu.svg';

import React, { Component } from 'react';

import { default as MenuButtons } from './MenuButtons/MenuButtons';

export default class MobileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = ({menu: false, compWillUnmount: false});
    this.handleOnClick = this.handleOnClick.bind(this);
  }
  handleOnClick() {
    if(this.state.menu === false) {
      this.setState({menu: true});
    } else {
      this.setState({compWillUnmount: true});
      window.setTimeout(() => {
        this.setState({menu: false});
        this.setState({compWillUnmount: false});
      }, 500);
      
    }
   
  }
  render() {
    return (
      <div id="MobileMenu">
        <img src={menuIcon} alt="Boton de menu" onClick={this.handleOnClick}></img>
        {this.state.menu ? <MenuButtons switchMenu={this.handleOnClick} willUnmount={this.state.compWillUnmount}/> : null }
      </div>
    )
  }
}
