import React, { Component } from 'react';
import './Locations.css';

import { default as StateSelector } from './StateSelector/StateSelector';
import { default as MapSection } from './MapSection/MapSection';
import { default as MobileSelector } from './MobileSelector/MobileSelector';


import Irapuato1 from './MapSection/locationImages/Irapuato/1.webp';
import Irapuato2 from './MapSection/locationImages/Irapuato/2.webp';
import Irapuato3 from './MapSection/locationImages/Irapuato/3.webp';
import Irapuato4 from './MapSection/locationImages/Irapuato/4.webp';

import Antea1 from './MapSection/locationImages/Antea/FoodCourt/3.webp';
import Antea2 from './MapSection/locationImages/Antea/FoodCourt/4.webp';
import Antea3 from './MapSection/locationImages/Antea/FoodCourt/5.webp';
import Antea4 from './MapSection/locationImages/Antea/FoodCourt/6.webp';
import Antea5 from './MapSection/locationImages/Antea/FoodCourt/7.webp';
import Antea6 from './MapSection/locationImages/Antea/FoodCourt/8.webp';

import Palacio1 from './MapSection/locationImages/Antea/Palacio/1.webp';
import Palacio2 from './MapSection/locationImages/Antea/Palacio/2.webp';
import Palacio3 from './MapSection/locationImages/Antea/Palacio/3.webp';
import Palacio4 from './MapSection/locationImages/Antea/Palacio/4.webp';

import Refugio1 from './MapSection/locationImages/Queretaro/HEB/1.webp';
import Refugio2 from './MapSection/locationImages/Queretaro/HEB/2.webp';
import Refugio3 from './MapSection/locationImages/Queretaro/HEB/3.webp';
import Refugio4 from './MapSection/locationImages/Queretaro/HEB/4.webp';
import Refugio5 from './MapSection/locationImages/Queretaro/HEB/5.webp';

import Dorado1 from './MapSection/locationImages/SLP/Dorado/1.webp';
import Dorado2 from './MapSection/locationImages/SLP/Dorado/2.webp';
import Dorado3 from './MapSection/locationImages/SLP/Dorado/3.webp';
import Dorado4 from './MapSection/locationImages/SLP/Dorado/4.webp';

import Sendero1 from './MapSection/locationImages/SLP/Sendero/1.webp';
import Sendero2 from './MapSection/locationImages/SLP/Sendero/2.webp';

import Proximamente1 from './MapSection/locationImages/Proximamente/1.webp';
import Proximamente2 from './MapSection/locationImages/Proximamente/2.webp';
import Proximamente3 from './MapSection/locationImages/Proximamente/3.webp';
import Proximamente4 from './MapSection/locationImages/Proximamente/4.webp';
import Proximamente5 from './MapSection/locationImages/Proximamente/5.webp';

export default class Locations extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      selected: 'QUERÉTARO', 
      selectedLocation: 'ANTEA (Food Court)', 
      image: Antea1,
      cibeles: [Irapuato1, Irapuato2, Irapuato3, Irapuato4],
      anteaFC: [Antea1, Antea2, Antea3, Antea4, Antea5, Antea6],
      anteaP: [Palacio1, Palacio2, Palacio3, Palacio4],
      heb: [Refugio1, Refugio2, Refugio3, Refugio4, Refugio5],
      sendero: [Sendero1, Sendero2],
      dorado: [Dorado1, Dorado2, Dorado3, Dorado4],
      proximamente: [Proximamente1, Proximamente2, Proximamente3, Proximamente4, Proximamente5],
      currentArray: [Antea1, Antea2, Antea3, Antea4, Antea5, Antea6],
      currentIndex: 0,
      map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.8588714284924!2d-100.43855598510123!3d20.675319886190884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d35a013183c8b3%3A0xb91aa0ff2d137fcd!2sAntea%20Lifestyle%20Center!5e0!3m2!1sen!2smx!4v1645579670448!5m2!1sen!2smx',
      address: 'San Luis, El Salitre, 76127 Santiago de Querétaro, Qro.',
      imageIntervalId: '',
    });
    this.changeSelected = this.changeSelected.bind(this);
    this.changeSelectedLocation = this.changeSelectedLocation.bind(this);
    this.changeLocationImage = this.changeLocationImage.bind(this);
    this.changeImage = this.changeImage.bind(this);
  }
  componentDidMount() {
    const interval1 = window.setInterval(() => {
      this.setState({currentIndex: this.state.currentIndex + 1});
      if(this.state.currentIndex > this.state.currentArray.length - 1) {
        this.setState({currentIndex: 0});
      }
      this.setState({image: this.state.currentArray[this.state.currentIndex]});
    }, 4000);
    this.setState({imageIntervalId: interval1});
  }
  componentWillUnmount() {
    clearInterval(this.state.imageIntervalId);
  }
  changeImage() {
    this.setState({image: this.state.currentArray[0]});
  }
  changeLocationImage(location) {
    switch(location) {

      case 'CENTRO COMERCIAL CIBELES':
        this.setState({ currentArray: this.state.cibeles, address: 'Boulevard a Villas de Irapuato 1443, Colonia Ejido de Irapuato, Irapuato, Guanajuato, México.'});
        this.setState({ map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.72719166998!2d-101.38196533507323!3d20.680675036187946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842c7fa574e6f815%3A0x1a4c34d21652adc0!2sPlaza%20Cibeles%2C%20Irapuato%2C%20Guanajuato!5e0!3m2!1sen!2smx!4v1645579520707!5m2!1sen!2smx' });
      break;

      case 'ANTEA (Food Court)':
        this.setState({ currentArray: this.state.anteaFC, address: 'San Luis, El Salitre, 76127 Santiago de Querétaro, Qro.' });
        this.setState({ map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.8588714284924!2d-100.43855598510123!3d20.675319886190884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d35a013183c8b3%3A0xb91aa0ff2d137fcd!2sAntea%20Lifestyle%20Center!5e0!3m2!1sen!2smx!4v1645579670448!5m2!1sen!2smx'});
      break;

      case 'ANTEA (Palacio de Hierro)':
        this.setState({ currentArray: this.state.anteaP, address: 'San Luis, El Salitre, 76127 Santiago de Querétaro, Qro.'});
        this.setState({ map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.8588714284924!2d-100.43855598510123!3d20.675319886190884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d35a013183c8b3%3A0xb91aa0ff2d137fcd!2sAntea%20Lifestyle%20Center!5e0!3m2!1sen!2smx!4v1645579670448!5m2!1sen!2smx'});
      break;

      case 'HEB (El Refugio)':
        this.setState({ currentArray: this.state.heb, address: 'Anillo Vial Fray Junípero Serra El Pozo Km. 6+900, Ex Hacienda, 76269 Santiago de Querétaro, Qro.' });
        this.setState({ map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3733.570355589498!2d-100.34529018510172!3d20.646362286206767!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d35d293aafa07b%3A0x7a7351e11f7c3a2a!2sH-E-B!5e0!3m2!1sen!2smx!4v1645579739192!5m2!1sen!2smx'});
      break;

      case 'CENTRO COMERCIAL EL DORADO':
        this.setState({ currentArray: this.state.dorado, address: 'Av Nereo Rodríguez Barragán 450, Col del Valle, 78200 San Luis, S.L.P.' });
        this.setState({ map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3695.2161553601168!2d-101.00638558507559!3d22.155842585398776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842a9f35faee4efd%3A0xb5d9d8bfd4aed1ee!2sCentro%20Comercial%20El%20Dorado!5e0!3m2!1sen!2smx!4v1645580042517!5m2!1sen!2smx'});
      break;

      case 'PLAZA SENDERO':
        this.setState({ currentArray: this.state.sendero, address: 'Av Benito Juarez No. 2005, Estrellas de Oriente, 78396 San Luis, S.L.P.' });
        this.setState({ map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3695.8613315567322!2d-100.92700938507599!3d22.131262185411643!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842aa39668e8bff7%3A0x7bce589c4397a548!2sPlaza%20Sendero%20San%20Luis!5e0!3m2!1sen!2smx!4v1645580270119!5m2!1sen!2smx'});
      break;

      case 'Premium Outlets (Próximamente)':
        this.setState({ currentArray: this.state.proximamente, address: 'VISITANOS PROXIMAMEMENTE' });
        this.setState({ map: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14941.3454782472!2d-100.462594!3d20.5743166!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd73fdd1afd9e245f!2sPremium%20Outlets%20Quer%C3%A9taro!5e0!3m2!1sen!2smx!4v1646000220424!5m2!1sen!2smx'});
      break;

      case 'GALERÍAS (Próximamente)':
        this.setState({ currentArray: this.state.proximamente, address: 'VISITANOS PROXIMAMEMENTE' });
        this.setState({ map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3735.263901998822!2d-100.40759158405311!3d20.577277708410975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d34533cd4d1cbd%3A0x80a42b6c0540a68!2zR2FsZXLDrWFzIFF1ZXLDqXRhcm8!5e0!3m2!1sen!2smx!4v1646000742538!5m2!1sen!2smx'});
      break;

      case 'CENTRO COMERCIAL ANGELÓPOLIS (Próximamente)':
        this.setState({ currentArray: this.state.proximamente, address: 'VISITANOS PROXIMAMEMENTE' });
        this.setState({ map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.7216486967254!2d-98.23493683406187!3d19.03198340829784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfc739da55561f%3A0xa65737406451f1d3!2sReserva%20Territorial%20Atlixc%C3%A1yotl%2C%20Centro%20Comercial%20Angelopolis%2C%20Puebla!5e0!3m2!1sen!2smx!4v1646000874913!5m2!1sen!2smx'});
      break;

      default:
        this.setState({ currentArray: this.state.cibeles });
      break;
    }
    window.setTimeout(() => {
      this.changeImage();
    }, 0);
  }
  changeSelected(event) {
    const id = event.target.id;
    this.setState({ selected: id});
  }
  changeSelectedLocation(event) {
    const name = event.target.name;
    this.setState({ selectedLocation: name});
    this.changeLocationImage(name);
  }
  render() {
    return (
      <div id="Locations">
        {this.props.isMobile === 'false' ? <StateSelector changeSelected={this.changeSelected} selected={this.state.selected} changeSelectedLocation={this.changeSelectedLocation} selectedLocation={this.state.selectedLocation}/> : <MobileSelector changeSelected={this.changeSelected} selected={this.state.selected} changeSelectedLocation={this.changeSelectedLocation} selectedLocation={this.state.selectedLocation}/>}
        <MapSection LocationImage={this.state.image} LocationMap={this.state.map} LocationAddress={this.state.address}/>
      </div>
    )
  }
}
