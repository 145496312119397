import React, { Component } from 'react';
import './GalleryMenu.css';

import filledCircle from './resources/filledcircle.svg';
import emptyCircle from './resources/emptycircle.svg';

export default class GalleryMenu extends Component {
  render() {
    let dotCounter = 0;
    return (
      <div className="GalleryMenu">
          {this.props.items.map((x) => {
              if (this.props.selected === x) {
                    dotCounter++;
                  return <img src={filledCircle} alt="selected option in the gallery" id={`galleryButton${dotCounter}`} key={`galleryButton${dotCounter}`} onClick={this.props.onClick}/>;
              } else {
                    dotCounter++;
                    return <img src={emptyCircle} alt="selected option in the gallery" id={`galleryButton${dotCounter}`} key={`galleryButton${dotCounter}`} onClick={this.props.onClick}/>
              }
          })}
      </div>
    )
  }
}
