import React, { Component } from 'react';
import './Footer.css';

import facebookLogo from './resources/facebookLogo.png';
import instagramLogo from './resources/instagramLogo.png';
import mailLogo from './resources/mailLogo.svg';
import phoneLogo from './resources/phoneLogo.svg';
export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = ({selected: ''});
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
  }
  handleEnter(event) {
    this.setState({selected: event.target.name});
  }
  handleOnClick(event) {
    window.location.href = event.target.name;
  }
  handleOnClickSocials(event) {
    window.open(event.target.name, '_blank');
  }
  render() {
    return (
      <div id="Footer">
          <div id="FooterSocials">
            <img src={facebookLogo} alt="Logotipo de facebook" onClick={this.handleOnClickSocials} name={'https://www.facebook.com/axesorios.stores'} target="_blank"/>
            <img src={instagramLogo} alt="Logotipo de whatsapp" onClick={this.handleOnClickSocials} name={'https://www.instagram.com/axesorios.stores/'} target="_blank"/>
            <img src={mailLogo} alt="Logotipo de email" id="mail-svg" onClick={this.handleOnClick} name={'mailto:hola@axesorios.mx'}/>
            <img src={phoneLogo} alt="Logotipo de telefono" id="phone-svg" onClick={this.handleOnClick} name={'tel:524424713727'}/>
          </div>
          <div id="FooterText">
            <p>Todos los derechos reservados &copy; Axesorios 2022</p>
            <p id="FEVIfirma">By FEVI with &#60;3</p>
          </div>
          
      </div>
    )
  }
}
