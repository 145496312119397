import React, { Component } from 'react';
import './NavBar.css';
import { default as NavBarLogo } from './NavBarLogo/NavBarLogo';
import { default as NavBarMenu } from './NavBarMenu/NavBarMenu';
export class NavBar extends Component {
  render() {
    return (
      <div id="navBar">
          <NavBarLogo />
          <NavBarMenu isMobile={this.props.isMobile} screenWidth={this.props.screenWidth}/>
      </div>
    )
  }
}
