import React, { Component } from 'react';
import './WhatsappButton.css';

import whatsapp from './resources/whatsapp.webp';

export default class WhatsappButton extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        window.open("https://wa.me/524424713727?text=Hola%21", "_blank");
    }
  render() {
    return (
      <div id="WhatsappButton">
          <button onClick={this.handleClick}><img alt="Escríbenos por whatsapp" src={whatsapp}></img></button>
      </div>
    )
  }
}
