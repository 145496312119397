import React, { Component } from 'react'
import { default as StateButton } from './StateButton/StateButton';

import './StateSelector.css';
export default class StateSelector extends Component {

  
  render() {
    return (
      <div id="StateSelectorWrapper">
          <h2>UBICACIONES</h2>
        <div id="StateSelector">
          <StateButton name="QUERÉTARO" id="QUERETARO" selectedOption={this.props.selected} onClick={this.props.changeSelected} locations={[`ANTEA (Food Court)`, `ANTEA (Palacio de Hierro)`, 'HEB (El Refugio)', 'GALERÍAS (Próximamente)', 'Premium Outlets (Próximamente)']} changeSelectedLocation={this.props.changeSelectedLocation} selectedLocation={this.props.selectedLocation} />  
          <StateButton name="SAN LUIS POTOSI" id="SAN LUIS POTOSI" selectedOption={this.props.selected} onClick={this.props.changeSelected} locations={['CENTRO COMERCIAL EL DORADO', 'PLAZA SENDERO']} changeSelectedLocation={this.props.changeSelectedLocation} selectedLocation={this.props.selectedLocation}/>
          <StateButton name="IRAPUATO" id="IRAPUATO" selectedOption={this.props.selected} onClick={this.props.changeSelected} locations={['CENTRO COMERCIAL CIBELES']} changeSelectedLocation={this.props.changeSelectedLocation} selectedLocation={this.props.selectedLocation}/>
          <StateButton name="PUEBLA" id="PUEBLA" selectedOption={this.props.selected} onClick={this.props.changeSelected} locations={['CENTRO COMERCIAL ANGELÓPOLIS (Próximamente)']} changeSelectedLocation={this.props.changeSelectedLocation} selectedLocation={this.props.selectedLocation}/>

        </div>
      </div>
    )
  }
}
