import './DesktopMenu.css';
import React, { Component } from 'react';

export default class DesktopMenu extends Component {
  constructor(props) {
    super(props);
    this.state = ({selected: 'PRODUCTOS'});
    this.handleClick = this.handleClick.bind(this);
    this.handleHover = this.handleHover.bind(this);
  }
  handleHover(event) {
    this.setState({selected: event.target.value});
  }
  handleClick() {
    window.scrollTo(0,document.getElementById(this.state.selected).offsetTop - (getComputedStyle(document.documentElement).fontSize.slice(0, Number.parseInt(getComputedStyle(document.documentElement).fontSize.length, 10) - 2) * 5));
  }
  render() {
    return (
      <div id="DesktopMenu">
          <button onClick={this.handleClick} onMouseEnter={this.handleHover} value='ProductGallery'><p>PRODUCTOS</p></button>
          <button onClick={this.handleClick} onMouseEnter={this.handleHover} value='Locations'><p>UBICACIONES</p></button>
          <button onClick={this.handleClick} onMouseEnter={this.handleHover} value='Nosotros'><p>NOSOTROS</p></button>
          <button onClick={this.handleClick} onMouseEnter={this.handleHover} value='Footer'><p>CONTACTO</p></button>
      </div>
    )
  }
}
